import { initCreateAccountEmail } from './forms/initCreateAccountEmail';
import { initContactUsForm } from './forms/initContactUsForm';
import { initModalCreateAccountEmail } from './forms/initModalCreateAccountEmail';
import { initModalCreateAccountEmailFull } from './forms/initModalCreateAccountEmailFull';
import { initAppendHiddenInputsToPlanForms } from '../../../../../Common/Resources/src_front/js/components/initAppendHiddenInputsToPlanForms';

export const initForms = (modal) => {
    initCreateAccountEmail();
    initContactUsForm();
    initModalCreateAccountEmail(modal);
    initModalCreateAccountEmailFull(modal);
    initAppendHiddenInputsToPlanForms();
};
