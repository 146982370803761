import Swiper from 'swiper/bundle';

export const initFeatureSlider = () => {
    const sliderSelector = '[data-init-feature-slider]';
    const sliderBox = document.querySelector(sliderSelector);
    if (!sliderBox) return;

    const featureSlider = new Swiper(sliderSelector, {
        slidesPerView: 1.2,
        spaceBetween: 16,
        loop: true,
        autoplay: {
            delay: 3000,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
        breakpoints: {
            1280: {
                slidesPerView: 6,
                spaceBetween: 30,
            },
            960: {
                slidesPerView: 4,
                spaceBetween: 16,
            },
            600: {
                slidesPerView: 3,
                spaceBetween: 16,
            },
        },
    });
};
