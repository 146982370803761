import { isValidMail } from './halper-functions/isValidMail';

export function initCreateAccountEmail() {
    // VARS
    const form = document.querySelector('[data-create-account-form]');
    if (!form) return;
    const userEmail = form.querySelector('[data-create-account-email]');
    const agreeCheckbox = form.querySelector('[data-create-account-checkbox]');
    const fieldsSelector = {
        userEmail: '[data-create-account-email]',
        agreeCheckbox: '[data-create-account-checkbox]',
    };
    const classShowErrorMessage = 'is-error';
    let isValidForm = true;

    // LISTENERS
    form.addEventListener('submit', handleOnSubmit, false);

    userEmail.addEventListener('focus', handleOnFocusFieldUserEmail, false);

    if (agreeCheckbox) {
        agreeCheckbox.addEventListener('change', handleOnChangeAgreeCheckbox, false);
    }

    // HANDLERS
    function handleOnSubmit(event) {
        validateUserEmail();
        validateAgreeCheckbox();

        if (agreeCheckbox) {
            validateAgreeCheckbox();
        }

        if (!isValidForm) {
            event.preventDefault();
            isValidForm = true;
        } else {
            window.pageLoader.show();
        }
    }

    function handleOnFocusFieldUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);
    }

    function handleOnChangeAgreeCheckbox() {
        hideErrorMessage(fieldsSelector.agreeCheckbox);
    }

    // METHODS & FUNCTIONS
    function validateUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);

        if (!userEmail.value.trim()) {
            showErrorMessage(fieldsSelector.userEmail, 'required');
            isValidForm = false;
        } else if (!isValidMail(userEmail.value.trim())) {
            showErrorMessage(fieldsSelector.userEmail, 'invalid');
            isValidForm = false;
        } else {
            hideErrorMessage(fieldsSelector.userEmail);
        }
    }

    function validateAgreeCheckbox() {
        if (!agreeCheckbox) {
            return;
        }

        hideErrorMessage(fieldsSelector.agreeCheckbox);

        if (agreeCheckbox.checked) {
            hideErrorMessage(fieldsSelector.agreeCheckbox);
        } else {
            showErrorMessage(fieldsSelector.agreeCheckbox, 'checkbox');
            isValidForm = false;
        }
    }

    function hideErrorMessage(selector) {
        const field = document.querySelector(selector);
        const errors = field.closest('[data-input-wrapper]').querySelectorAll('[data-field-error]');

        field.classList.remove(classShowErrorMessage);
        [...errors].forEach((error) => {
            error.classList.remove(classShowErrorMessage);
        });
    }

    function showErrorMessage(selector, type) {
        const field = document.querySelector(selector);
        const error = field.closest('[data-input-wrapper]').querySelector(`[data-field-error="${type}"]`);

        field.classList.add(classShowErrorMessage);
        error.classList.add(classShowErrorMessage);
    }
}
