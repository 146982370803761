export const initStickyBanner = () => {
    // VARS
    const banner = document.querySelector('[data-sticky-banner]');
    const bannerEventTarget = document.querySelector('[data-sticky-banner-event-target]');
    if (!banner) return;
    const bannerTop = banner.offsetTop;

    // LISTENERS
    window.addEventListener('scroll', handleOnScroll, false);

    // HANDLERS
    function handleOnScroll() {
        toggleStickyBanner();
    }

    // FUNCTIONS
    function toggleStickyBanner() {
        if (window.scrollY >= bannerTop) {
            setStickyStyle();
        } else {
            removeStickyStyle();
        }
    }

    function setStickyStyle() {
        bannerEventTarget.style.marginBottom = banner.offsetHeight + 'px';
        banner.classList.add('fixed-nav');
    }

    function removeStickyStyle() {
        bannerEventTarget.style.marginBottom = 0;
        banner.classList.remove('fixed-nav');
    }
};
