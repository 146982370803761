import Swiper from 'swiper/bundle';

export const initQuoteSlider = () => {
    new Swiper('[data-swiper-quote-slider]', {
        slidesPerView: 'auto',
        direction: 'horizontal',
        speed: 400,
        grabCursor: true,
        centeredSlidesBounds: false,
        centeredSlides: false,
        loop: true,
        pagination: {
            el: '.swiper-pagination-pc',
            clickable: true,
        },
        navigation: {
            nextEl: '.slider-block--left--navigation--right-pc',
            prevEl: '.slider-block--left--navigation--left-pc',
        },
        on: {
            reachEnd: function () {
                this.snapGrid = [...this.slidesGrid];
            },
        },
        breakpoints: {
            1140: {
                spaceBetween: 40,
            },
            900: {
                spaceBetween: 20,
                initialSlide: 0,
            },
            320: {
                spaceBetween: 10,
                centeredSlides: true,
                initialSlide: 1,
                navigation: {
                    nextEl: '.slider-block--left--navigation--right',
                    prevEl: '.slider-block--left--navigation--left',
                    slidesOffsetBefore: 10,
                },
                pagination: {
                    el: '.swiper-pagination-mob',
                    clickable: true,
                },
            },
        },
    });
};
