import Swiper from 'swiper/bundle';

export const initSliderTestimonials = () => {
    new Swiper('[data-testimonials]', {
        loop: true,
        centeredSlides: true,
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    });
};
