import Swiper from 'swiper/bundle';

export const initSliderPhoneNumberTestimonials = () => {
    new Swiper('[data-number-testimonials]', {
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
        },

        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            clickable: true,
        },
        breakpoints: {
            280: {
                slidesPerView: 1,
                spaceBetween: 10,
            },

            550: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
        },
    });
};
