import { setCookie } from '../helper-function/coockie/setCookie';
import { getCookie } from '../helper-function/coockie/getCookie';
import { deleteCookie } from '../helper-function/coockie/deleteCookie';

export const initBannerTimer = () => {
    // VARS
    const timerBanner = document.querySelector('[data-timer-banner]');
    if (!timerBanner) return;
    const minutesFirstNumber = timerBanner.querySelector('[data-minutes-first-number]');
    const minutesSecondNumber = timerBanner.querySelector('[data-minutes-second-number]');
    const secondsFirstNumber = timerBanner.querySelector('[data-seconds-first-number]');
    const secondsSecondNumber = timerBanner.querySelector('[data-seconds-second-number]');
    const hourFirstNumber = timerBanner.querySelector('[data-hour-first-number]');
    const hourSecondNumber = timerBanner.querySelector('[data-hour-second-number]');

    // INITS
    startTimerParent();

    // FUNCTIONS
    function startTimerParent() {
        const totalTimer50off = Date.parse(new Date()) / 1000;
        const timer50off = totalTimer50off + 26400;

        if (
            !getCookie('spybubbleTimerOff') ||
            getCookie('spybubbleTimerOff') == null ||
            getCookie('spybubbleTimerOff') - totalTimer50off < 0
        ) {
            setCookie('spybubbleTimerOff', timer50off, timer50off);
        }

        const totalSeconds = getCookie('spybubbleTimerOff') - totalTimer50off;
        const hMain = Math.trunc(totalSeconds / 3600) % 24;
        const mMain = Math.trunc(totalSeconds / 60) % 60;
        const sMain = Math.trunc(totalSeconds) % 60;

        outputSpanTimer(String(hMain), hourSecondNumber, hourFirstNumber);
        outputSpanTimer(String(mMain), minutesSecondNumber, minutesFirstNumber);
        outputSpanTimer(String(sMain), secondsSecondNumber, secondsFirstNumber);

        if (!hMain && !mMain && !sMain) {
            deleteCookie('spybubbleTimerOff');
            window.location.reload();
            return;
        }

        setTimeout(startTimerParent, 1000);
    }

    function outputSpanTimer(textInto, currSpanT, currSpanO) {
        currSpanT.textContent = '';
        currSpanO.textContent = '';

        if (!textInto[1]) {
            if (!textInto) {
                currSpanO.textContent = '0';
                currSpanT.textContent = '0';
            } else {
                currSpanO.textContent = '0';
                currSpanT.textContent = textInto[0];
            }
        } else {
            currSpanT.textContent = textInto[1];
            currSpanO.textContent = textInto[0];
        }
    }
};
