import { initSliderTestimonials } from './sliders/initSliderTestimonials';
import { initSliderPhoneNumberTestimonials } from './sliders/initSliderPhoneNumberTestimonials';
import { initFeatureSlider } from './sliders/initFeatureSlider';
import { initQuoteSlider } from './sliders/initQuoteSlider';

export const initSliders = () => {
    initSliderTestimonials();
    initSliderPhoneNumberTestimonials();
    initFeatureSlider();
    initQuoteSlider();
};
