export const initLangSwitcher = () => {
    // VARS
    const langMenusList = document.querySelectorAll('[data-dropdown-list]');
    const langButtons = document.querySelectorAll('[data-dropdown-menu-button]');
    const dropdownIcon = document.querySelector('[data-dropdown-icon]');

    // LISTENERS
    langButtons.forEach((langButton) => {
        langButton.addEventListener('click', handleOnClickButton, false);
    });

    document.addEventListener('click', handleOnClickDocument, false);

    document.addEventListener('scroll', handleOnScroll, false);

    document.addEventListener('keydown', handleOnKeydown, false);

    // HANDLERS
    function handleOnClickButton() {
        const langMenuList = this.closest('[data-dropdown-menu]').querySelector('[data-dropdown-list]');
        toggleDropDown(langMenuList);
    }

    function handleOnClickDocument(event) {
        if (!event.target.closest('[data-dropdown-menu]')) {
            closeDropDown();
        }
    }

    function handleOnScroll() {
        closeDropDown();
    }

    function handleOnKeydown(event) {
        if (event.key === 'Escape') {
            closeDropDown();
        }
    }

    // FUNCTIONS & METHODS
    function toggleDropDown(langMenuList) {
        dropdownIcon?.classList.toggle('active');
        langMenuList.classList.contains('collapse') ? closeDropDown() : openDropDown(langMenuList);
    }

    function openDropDown(langMenuList) {
        langMenuList.classList.add('collapse');
    }

    function closeDropDown() {
        langMenusList.forEach((langMenuList) => {
            langMenuList.classList.remove('collapse');
        });
    }
};
